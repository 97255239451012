import React, { useState, Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  Box,
  InputLabel,
  Input,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup, Radio
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import CheckIcon from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import CustomerAddressBookController, { Props, configJSON } from "./CustomerAddressBookController.web";
import {
  Menu,
  MenuItem,
} from "../../../../node_modules/@szhsin/react-menu";
import Loader from "../../../components/src/Loader.web"
import { withStyles } from "@material-ui/core/styles";
const styles = {
  userouter: {
    // padding: "50px 0",
    "& h2": {
      fontSize: "18px",
      lineHeight: "22px",
      color: "#121212",
      fontWeight: "700",
      borderBottom: "1px solid #e6e6e6",
      margin: "0",
      textTransform: "uppercase",
      padding: "0 0 20px",
    },
    "& .user-detail-wrapper": {
      padding: "30px 20px",
      background: "#f3f3f3",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner": {
      background: "#fff",
      margin: "0 0 20px",
      padding: "29px 20px",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner h4": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 20px",
    },
    "& .inner-detail-wrapper": {
      maxWidth: "510px",
      margin: "0 auto",
      "@media (max-width:767px)": {
        maxWidth: "100%",
      },
    },
    "& .btn-wrapper": {
      textAlign: "center",
      padding: "15px 0 0",
      "@media (max-width:767px)": {
        padding: "0 0 0",
      },
    },
    "& .user-address-wrapper .custom-radio": {
      flexDirection: "column",
    },
    "& .custom-radio": {
      display: "flex",
      flexDirection: "row",
    },
    "& .custom-radio .MuiRadio-root": {
      color: "#000",
    },
    "& .title-btn-wrapper": {
      margin: "0 0 10px",
    },
    "& .title-btn-wrapper p": {
      margin: "0 0 0x",
      fontSize: "14px",
      lineheight: "16px",
      color: "#121212",
      fontWeight: "500",
    },
    "& .user-address-wrapper .user-address-list:not(:last-child)": {
      //   borderBottom: "1px solid #e3e3e3",
      padding: "0 0 25px",
      margin: "0 0 20px",
    },
    "& .user-address-list h6": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 15px",
    },
    "& .user-address-list p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#5d5d66",
      fontWeight: "400",
      margin: "0 0 15px",
      maxWidth: "500px",
      textTransform: "capitalize",
      "@media (max-width:767px)": {
        maxWidth: "100%",
      },
    },
    "& .user-address-list a": {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      color: "#121212",
      textTransform: "uppercase",
      border: "1px solid #212121",
      borderRadius: "4px",
      padding: "4px 7px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .user-address-list": {
      display: "flex",
      justifyContent: "space-between"
    },
    "& .user-address-list a:hover": {
      background: "#212121",
      color: "#fff",
    },
    // "& .adress-detail-wrapper": {
    //   padding: "0 0 0 33px",
    // },
    // "& .address-title-wrapper": {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "space-between",
    // },
    "& .address-title-wrapper label .MuiFormControlLabel-label": {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "600",
    },
    "& .check": {
      position: "absolute",
      right: "0",
      bottom: "0",
      color: "#21c998",
      // display: "none",
    },
    "& .valid .check": {
      display: "block",
    },
    "& .valid-number": {
      position: "absolute",
      right: "0",
      bottom: "7px",
      color: "#0057ff",
      display: "none",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      textDecoration: "none",

    },
    "& .valid-email": {
      // position: "absolute",
      // right: "0",
      // bottom: "7px",
      color: "#0057ff",
      // display: "none",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      textDecoration: "none",

    },

    "& .verify .valid-number": {
      display: "block",
    },
  },
};
// Edit Profile End
const error_msg = {
  color: "red"
}

const profileList = [
  { id: "1", category: "My Profile" },
  { id: "2", category: "My Orders" },
  { id: "3", category: "Shopping With Friends" },
  { id: "4", category: "Address Book" },
  { id: "5", category: "Coupons" },
  { id: "6", category: "Policies" },
  { id: "7", category: "Settings" },
  { id: "8", category: "Loyalty Points" },
];
export class CustomerEditProfile extends CustomerAddressBookController {
  constructor(props: Props) {
    super(props);
  }

  renderCustomerEditProfile = () => {
    const { classes } = this.props;
    return (<>

      <div ref={this.myRef} className={classes.userouter}>
        <Grid
        >
          <div className="user-detail-wrapper">
            <div className="user-detail-inner">
              <div className="inner-detail-wrapper">
                <h4>Personal Details</h4>
                <div className="form-group">
                  <InputLabel htmlFor="name">Name*</InputLabel>
                  <FormControl>
                    <Input
                      type="text"
                      id="name"
                      aria-describedby="my-helper-text"
                      value={this.state.editProfileName}
                      onChange={(e) => this.setState({ editProfileName: e.target.value })}
                    />
                  </FormControl>
                  <p style={error_msg}>
                    {this.state.editProfileNameError}
                  </p>
                </div>
                <div className="form-group valid">
                  <InputLabel htmlFor="email">Email*</InputLabel>
                  <FormControl>
                    <Input
                      type="email"
                      id="email"
                      aria-describedby="my-helper-text"
                      value={this.state.editProfileemail}
                      onChange={(e) => this.setState({ editProfileemail: e.target.value })}
                    />
                    {this.state.profileDetail.attributes?.email_verified ? <>  <span className="check">
                      <CheckIcon />
                    </span> </> : <>
                      <span className="check">     <Link className="valid-email"
                        onClick={(e: any) => {
                          this.setState({ resendActivateEmail: false })
                          this.otpTimerEmail()
                          this.sendOtpEmail()
                          this.setState({
                            MailVerify: true,
                          });
                        }}

                      >
                        Verify
                      </Link>  </span> </>}
                  </FormControl>
                  <p style={error_msg}>
                    {this.state.editProfileEmailError}
                  </p>
                </div>
                <div className="form-group verify">
                  <InputLabel htmlFor="number">Phone Number*</InputLabel>
                  <FormControl>
                    <Input
                      type="number"
                      id="number"
                      aria-describedby="my-helper-text"
                      value={this.state.editProfilePhoneNumber}
                      onChange={(e) => this.setState({ editProfilePhoneNumber: e.target.value })}
                    />

                    {this.state.profileDetail.attributes?.phone_verified ? <>  <span className="check">
                      <CheckIcon />
                    </span> </> : <>
                      <span className="check">     <Link className="valid-email"
                        onClick={(e: any) => {
                          this.setState({ resendActivatePhone: false })
                          this.otpTimerPhone()
                          this.sendOtpPhone()
                          this.setState({
                            PhoneVerify: true,
                          });
                        }}

                      >
                        Verify
                      </Link>  </span> </>}
                  </FormControl>
                  <p style={error_msg}>
                    {this.state.editProfilePhoneError}
                  </p>
                </div>
                <div className="form-group">
                  <InputLabel htmlFor="date">Date of Birth*</InputLabel>
                  <FormControl>
                    <DatePicker
                      placeholderText="dd-mm-yyyy"
                      onChange={(date: Date) => { const dob: any = moment(date.toString()).format("DD-MM-YYYY"); this.setState({ editProfileDateOfBirth: dob }) }}
                      value={this.state.editProfileDateOfBirth}
                      id="dob"
                      name="dob"
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                      autoComplete="off"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FormControl>
                  <p style={error_msg}>
                    {this.state.editProfileDateOfBirthError}
                  </p>
                </div>
                <div className="form-group">
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup className="custom-radio" name="gender">
                    <FormControlLabel
                      onChange={(e: any) => this.setState({ editProfileGender: e.target.value })}
                      checked={this.state.editProfileGender == "male"}
                      value="male"
                      control={<Radio color="default" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      checked={this.state.editProfileGender == "female"}
                      onChange={(e: any) => this.setState({ editProfileGender: e.target.value })}
                      control={<Radio color="default" />}
                      label="Female"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div className="user-detail-inner">
              <Loader loading={this.state.addressBookLoader} />
              <div className="inner-detail-wrapper">
                <h4>Address Book</h4>
                <div className="title-btn-wrapper">
                  <p>Address</p>
                  <Button
                    variant="contained"
                    className="outline-btn"
                    type="button"
                    onClick={(e) => {
                      this.setState({
                        showNewAddressDialog: true,
                      });
                    }}
                  >
                    Add New Address
                  </Button>
                </div>
                {/* display address  */}

                {this.state.addressData.length == 0 ? <><h6>No Address</h6></> : this.state.addressData.map((address: any) => <div className="user-address-wrapper">

                  <div className="user-address-list">
                    <div className="address-title-wrapper">

                      <div className="adress-detail-wrapper">
                        <h6>{address.attributes.name}</h6>
                        <p>
                          {address.attributes.address}, {address.attributes.city}, {address.attributes.state} - {address.attributes.zip_code}
                        </p>
                        <Link href="#">{address.attributes.save_address_as}</Link>
                      </div>

                    </div>
                    <div
                      onClick={() =>
                        this.setState({
                          addressId: address.attributes.id, editName: address.attributes.name,
                          editPhoneNumber: address.attributes.phone_number,
                          editAddress: address.attributes.address,
                          editTown: address.attributes.town,
                          editZipCode: address.attributes.zip_code,
                          editCity: address.attributes.city,
                          editState: address.attributes.state,
                          editSaveAddressAs: address.attributes.save_address_as
                        },


                          () => console.log(this.state.addressId, this.state.editName))}
                    >
                      <Menu
                        menuButton={
                          <div>
                            <IconButton>
                              <MoreHorizIcon />
                            </IconButton>
                          </div>
                        }
                      >
                        <MenuItem onClick={(e) => {
                          this.setState({
                            showEditAddressDialog: true,
                          });
                        }}>Edit</MenuItem>
                        <MenuItem onClick={() => this.setState({
                          isDelete: true
                        })} >Delete</MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <hr />

                </div>)}
              </div>
            </div>
            <div className="btn-wrapper">
              <Button
                variant="contained"
                className="black-btn"
                type="button"
                onClick={() => {
                  this.myRef.current?.scrollIntoView({ behavior: 'auto' });;
                  this.EditProfile()
                }}
              >
                Save Profile
              </Button>
            </div>
          </div>
        </Grid>
      </div>
      {/* Edit Profile End */}

      {/* verify number */}
      <Dialog
        aria-labelledby="customized-dialog-duplicate"
        open={this.state.PhoneVerify}
        className="phone-verify"
      >
        <DialogTitle id="customized-dialog-title">
          <span>Enter OTP To Verify Phone Number</span>
          <CloseIcon
            className="close-dialog"
            onClick={(e) => {
              this.setState({
                PhoneVerify: false, otpPinPhone: "", otpPhoneError: ""
              });
            }}
          />
        </DialogTitle>
        <DialogContent style={{ width: "480px" }}>
          <div className="phone-verify-content">
            <p>Please enter OTP sent to <span>{this.state.editProfilePhoneNumber}</span></p>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Enter OTP</InputLabel>
              <FormControl>
                <Input value={this.state.otpPinPhone} onChange={(e: any) => this.setState({ otpPinPhone: e.target.value })} type="text" id="my-input" aria-describedby="my-helper-text" />
              </FormControl>
              <p style={error_msg}>
                {this.state.otpPhoneError}
              </p>
            </div>
            {this.state.resendActivatePhone ? <> <p onClick={(e) => {
              this.setState({ resendActivatePhone: false })
              this.otpTimerPhone()
              this.sendOtpPhone();

            }} className="resend-code">
              <Link

              ><h6>Resend OTP</h6></Link>
            </p></> : <p className="resend-code">



              Resend OTP  in <span> {this.state.otpTimerPhone}s</span>
            </p>}
          </div>
          <div className="btn-wrapper">
            <Button onClick={() => { this.verifyPhone() }} href="" type="submit" variant="contained" className="black-btn">
              Verify
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* verify email  */}
      <Dialog
        aria-labelledby="customized-dialog-duplicate"
        open={this.state.MailVerify}
        className="phone-verify"
      >
        <DialogTitle id="customized-dialog-title">
          <span>Enter OTP To Verify Email</span>
          <CloseIcon
            className="close-dialog"
            onClick={(e) => {
              this.setState({
                MailVerify: false, otpPinEmail: "", otpEmailError: ""
              });
            }}
          />
        </DialogTitle>
        <DialogContent style={{ width: "480px" }}>
          <div className="phone-verify-content">
            <p>Please enter OTP sent to <span>{this.state.editProfileemail}</span></p>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Enter OTP</InputLabel>
              <FormControl>
                <Input value={this.state.otpPinEmail} onChange={(e: any) => this.setState({ otpPinEmail: e.target.value })} type="text" id="my-input" aria-describedby="my-helper-text" />
              </FormControl>
              <p style={error_msg}>
                {this.state.otpEmailError}
              </p>
            </div>
            {this.state.resendActivateEmail ? <> <p onClick={(e) => {
              this.setState({ resendActivateEmail: false })
              this.otpTimerEmail()
              this.sendOtpEmail();

            }} className="resend-code">
              <Link
              ><h6>Resend OTP</h6></Link>
            </p></> : <p className="resend-code">
              Resend OTP  in <span> {this.state.otpTimerEmail}s</span>
            </p>}
          </div>
          <div className="btn-wrapper">
            <Button onClick={() => { this.verifyEmail() }} type="submit" variant="contained" className="black-btn">
              Verify
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* add new address dialog box   */}
      <Dialog
        // onClose={this.handleCloseNewAddressDialog}
        aria-labelledby="customized-dialog-duplicate"
        open={this.state.showNewAddressDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ marginRight: "15px" }}
        >
          Add New Address
          <span style={{ position: "absolute", right: "10px" }}>
            <CloseIcon onClick={this.handleCloseNewAddressDialog} />
          </span>
        </DialogTitle>
        <DialogContent style={{ width: "450px" }}>
          <div className="form-group">
            <InputLabel htmlFor="my-input">
              Name<sup>*</sup>
            </InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }); console.log(this.state.name) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.nameError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">Mobile Number<sup>*</sup></InputLabel>
            <FormControl>
              <Input type="number" id="my-input" aria-describedby="my-helper-text" value={this.state.mobile} onChange={(e) => { this.setState({ mobile: e.target.value }); console.log(this.state.mobile) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.mobileError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">Pin Code<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" type="number" aria-describedby="my-helper-text" value={this.state.pincode} onChange={(e) => { this.setState({ pincode: e.target.value }); console.log(this.state.pincode) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.pincodeError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">
              Address(House No, Building, street)<sup>*</sup>
            </InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.address} onChange={(e) => { this.setState({ address: e.target.value }); console.log(this.state.address) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.addressError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">Locality / Town<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.town} onChange={(e) => { this.setState({ town: e.target.value }); console.log(this.state.town) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.townError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">City / District<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); console.log(this.state.city) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.cityError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">State<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); console.log(this.state.state) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.stateError}
            </p>
          </div>
          <div className="form-group">
            <RadioGroup className="align-row" aria-label="address" name="address">
              <h4>Save Address As</h4>
              {/* <FormControlLabel value="1"  checked={this.state.save_add === "home"} control={<Radio />} label="home"  onChange={()=>this.setState({save_add:"home"})}/>
              <FormControlLabel value="2" control={<Radio />} label="office"   onChange={()=>{this.setState({save_add:"office"});console.log(this.state.save_add)}}/> */}
              <FormControlLabel value="home" checked={this.state.save_add === 'home'} control={<Radio />} label="home" onChange={(e: any) => { this.setState({ save_add: e.target.value }, () => console.log(this.state.save_add)) }} />
              <FormControlLabel value="office" control={<Radio />} checked={this.state.save_add === 'office'} label="office" onChange={(e: any) => { this.setState({ save_add: e.target.value }, () => console.log(this.state.save_add)) }} />
            </RadioGroup>
            <p style={error_msg}>
              {this.state.save_addError}
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button
            type="submit"
            variant="contained"
            style={{ width: "100%" }}
            className="black-btn order-btn"
            onClick={(e) => {

              this.addThisDeliveryAddress()
            }
            }
          >
            Add Address
          </Button>
        </DialogActions>
      </Dialog>



      {/* edit address   */}
      {/* add new address dialog box   */}
      <Dialog
        // onClose={this.handleCloseNewAddressDialog}
        aria-labelledby="customized-dialog-duplicate"
        open={this.state.showEditAddressDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ marginRight: "15px" }}
        >
          Edit Address
          <span style={{ position: "absolute", right: "10px" }}>
            <CloseIcon onClick={this.handleCloseEditAddressDialog} />
          </span>
        </DialogTitle>
        <DialogContent style={{ width: "450px" }}>
          <div className="form-group">
            <InputLabel htmlFor="my-input">
              Name<sup>*</sup>
            </InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.editName} onChange={(e) => { this.setState({ editName: e.target.value }); console.log(this.state.editName) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.nameError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">Mobile Number<sup>*</sup></InputLabel>
            <FormControl>
              <Input type="number" id="my-input" aria-describedby="my-helper-text" value={this.state.editPhoneNumber} onChange={(e) => { this.setState({ editPhoneNumber: e.target.value }); console.log(this.state.mobile) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.mobileError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">Pin Code<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" type="number" aria-describedby="my-helper-text" value={this.state.editZipCode} onChange={(e) => { this.setState({ editZipCode: e.target.value }); console.log(this.state.pincode) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.pincodeError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">
              Address(House No, Building, street)<sup>*</sup>
            </InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.editAddress} onChange={(e) => { this.setState({ editAddress: e.target.value }); console.log(this.state.address) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.addressError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">Locality / Town<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.editTown} onChange={(e) => { this.setState({ editTown: e.target.value }); console.log(this.state.town) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.townError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">City / District<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.editCity} onChange={(e) => { this.setState({ editCity: e.target.value }); console.log(this.state.city) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.cityError}
            </p>
          </div>
          <div className="form-group">
            <InputLabel htmlFor="my-input">State<sup>*</sup></InputLabel>
            <FormControl>
              <Input id="my-input" aria-describedby="my-helper-text" value={this.state.editState} onChange={(e) => { this.setState({ editState: e.target.value }); console.log(this.state.state) }} />
            </FormControl>
            <p style={error_msg}>
              {this.state.stateError}
            </p>
          </div>
          <div className="form-group">
            <RadioGroup className="align-row" aria-label="address" name="address">
              <h4>Save Address As</h4>
              <FormControlLabel value="home" checked={this.state.editSaveAddressAs === 'home'} control={<Radio />} label="home" onChange={(e: any) => { this.setState({ editSaveAddressAs: e.target.value }, () => console.log(this.state.save_add)) }} />
              <FormControlLabel value="office" control={<Radio />} checked={this.state.editSaveAddressAs === 'office'} label="office" onChange={(e: any) => { this.setState({ editSaveAddressAs: e.target.value }, () => console.log(this.state.save_add)) }} />
            </RadioGroup>
            <p style={error_msg}>
              {this.state.save_addError}
            </p>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <Button
            type="submit"
            variant="contained"
            style={{ width: "100%" }}
            className="black-btn order-btn"
            onClick={(e) => {
              this.EditAddress();
            }
            }
          >
            Save Address
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete box  */}
      <Dialog
        open={this.state.isDelete}
        aria-labelledby="simple-dialog-title"
        fullWidth={true} maxWidth={"xs"}
      >
        <Box className="myBox">
          <Box textAlign="right" p={2}>
            <span className="close-btn">
              <CloseIcon
                onClick={() => this.handleClose()}
              ></CloseIcon>
            </span>
          </Box>
          <Box className="modal-head" textAlign="center" fontWeight="600" p={1}>
            <Typography variant="h5"> Are you sure, you want to delete the address? <b>
              {/* {this.state.sizeChartToDelete.attributes?.product_name} */}
              <p style={{ fontFamily: "serif" }} >Label : {this.state.editSaveAddressAs}</p>
            </b>
              {/* size chart? */}
            </Typography>
          </Box>
          <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">
            <Button className="black-btn"
              onClick={() => this.handleClose()}
              size="medium"
            >
              No
            </Button>
            <Button className="black-btn"

              onClick={(e) => {
                this.deleteAddress()
              }}
              size="medium"
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Dialog>

    </>)
  }
  render() {
    const { classes } = this.props;

    return (
      <>

        <CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend} >
          {this.renderCustomerEditProfile()}
        </CustomerAccountSideBarWeb>
      </>

    );
  }
}

export default withStyles(styles)(CustomerEditProfile);
