

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setTimeout } from "timers";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  handleCartCount(number: Number): Function;
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data: any;
  loading: boolean;
  navigate: boolean;
  token: string | null;
  open: boolean;
  subMenuActive: boolean;
  placeOrderData: any;
  activeCartId: any;
  productSize: boolean;
  productQty: boolean;
  applyCoupon: boolean;
  payment: boolean;
  ShoppingCartData: any;
  priceDetailsData: any;
  coupons: any;
  details: boolean;
  appliedcoupon: any;
  Removeappliedcoupon: any;
  quantity: any;
  quantityUpdateData: any;
  productQuantityId: any;
  sizeArray: any;
  sizeUpdateData: any;
  size: any;
  productSizeId: any;
  loyaltyPoints: any;
  isRemoved: boolean;
  isMoveToWishlist: boolean;
  isDelete: boolean;
  ShoppingRemoveBag: any;
  sizeQuantityLoader: boolean;
  couponsCatalogueIds: any;
  couponCode: any;
  invalidCoupon: any;
  invalidAlertMessage: any;
  loyaltyDisplay: any;
  loyaltyCheck: any;
  applyLoyaltyPoints: any;
  redeemLoyalty: any;
  useLoyaltyPoints: any;
  removeLoyaltyPoints: any;
  loyaltyPointsError: any;
  exchangeFlow: boolean;
  placeExchangeOrderData: any;
  exchangeItemOrderId: any;
  ShoppingRemoveBagOnExchangeDiscard: any;
  ExchangeFlowDiscarded: boolean;
  alert: boolean


  // shoppingCartloading:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}



export default class ShoppingController extends BlockComponent<Props, S, SS> {
  AddToWishCallId: any;
  RemoveToWishCallId: any;
  PlaceOrderCallId: any;
  ActiveCartCallId: any;
  ShoppingCartDataCallId: any;
  RemoveShoppingBagCallId: any;
  priceDetailsDataCallId: any;
  couponsDataCallId: any;
  appliedcouponDataCallId: any;
  quantityUpdateCallId: any;
  sizeUpdateCallId: any;
  loyaltyPointsCallId: any;
  RemoveappliedcouponDataCallId: any;
  applyLoyaltyPointsCallId: any;
  removeLoyaltyPointsCallId: any;
  PlaceExchangeOrderCallId: any;
  RemoveShoppingBagOnExchangeDiscardCallId: any
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      data: [],
      loading: false,
      navigate: false,
      token: "",
      open: false,
      subMenuActive: false,
      placeOrderData: [],
      activeCartId: [],
      productSize: false,
      productQty: false,
      applyCoupon: false,
      payment: false,
      ShoppingCartData: [],
      priceDetailsData: [],
      coupons: [],
      details: false,
      appliedcoupon: [],
      Removeappliedcoupon: [],
      quantity: 1,
      quantityUpdateData: [],
      productQuantityId: "",
      sizeArray: [],
      sizeUpdateData: [],
      productSizeId: "",
      size: "",
      loyaltyPoints: [],
      isRemoved: false,
      isMoveToWishlist: false,
      isDelete: false,
      ShoppingRemoveBag: "",
      sizeQuantityLoader: false,
      couponsCatalogueIds: [],
      couponCode: "",
      invalidCoupon: false,
      invalidAlertMessage: "",
      loyaltyDisplay: "",
      loyaltyCheck: "Enter Points",
      applyLoyaltyPoints: [],
      redeemLoyalty: "choose_custom_point",
      useLoyaltyPoints: "",
      removeLoyaltyPoints: [],
      loyaltyPointsError: "",
      exchangeFlow: false,
      placeExchangeOrderData: [],
      exchangeItemOrderId: "",
      ShoppingRemoveBagOnExchangeDiscard: "",
      ExchangeFlowDiscarded: false,
      alert: false

      // shoppingCartloading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start

    // place order start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PlaceOrderCallId !== null &&
      this.PlaceOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PlaceOrderCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ placeOrderData: responseJson.data });
      // console.log('////////////// place order------------------------------------------------------', this.state.placeOrderData);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // place order end 


    // quantity update start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.quantityUpdateCallId !== null &&
      this.quantityUpdateCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.quantityUpdateCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        this.setState({ quantityUpdateData: responseJson.data }, () => {
          this.shoppingCartData();
          this.shoppingPriceData()
          this.setState({ sizeQuantityLoader: false })
        });
        // console.log('////////////// quantityUpdateData------------------------------------------------------', this.state.quantityUpdateData);

      }

      // this.setState({ quantityUpdateData: responseJson.data });
      // console.log('////////////// quantityUpdateData------------------------------------------------------', this.state.quantityUpdateData);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // quantity update end 


    // sizeupdate start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.sizeUpdateCallId !== null &&
      this.sizeUpdateCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.sizeUpdateCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // this.setState({ sizeUpdateData: responseJson.data });
      // console.log('////////////// sizeUpdateData------------------------------------------------------', this.state.sizeUpdateData);
      // this.setState({sizeQuantityLoader:false})


      if (responseJson && responseJson.data) {


        this.setState({ sizeUpdateData: responseJson.data }, () => {
          this.shoppingCartData();
          this.shoppingPriceData()
          this.setState({ sizeQuantityLoader: false })
        });
        // console.log('////////////// sizeUpdateData------------------------------------------------------', this.state.sizeUpdateData);

      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // size update end 

    // shopping cart data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ShoppingCartDataCallId !== null &&
      this.ShoppingCartDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ShoppingCartDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data && responseJson.data) {
        this.setState({ ShoppingCartData: responseJson.data }, () => {
          const removeondiscard: any = this.state.ShoppingCartData[0].id
          this.setState({ ShoppingRemoveBagOnExchangeDiscard: removeondiscard })
          const cartItemList: any[] = responseJson.data;
          if (cartItemList) {
            localStorage.setItem('cartItemCount', cartItemList.length.toString());
            this.props.handleCartCount(cartItemList.length);
          } else {
            localStorage.setItem('cartItemCount', "0");
            this.props.handleCartCount(0);
          }
          let couponsCatalogueIds: string[] = []
          this.state.ShoppingCartData.map((ids: any) => {
            couponsCatalogueIds.push(ids.attributes.catalogue_id)
          })
          this.setState({
            couponsCatalogueIds: couponsCatalogueIds
          }, () => this.coupons())

        });
        this.shoppingPriceData()
        // this.setState({ shoppingCartloading: false });
        // console.log(this.state.couponsCatalogueIds, "cat ids")
        // console.log('////////////// ShoppingCartData data------------------------------------------------------', this.state.ShoppingCartData);
      }
      else {
        this.setState({ ShoppingCartData: "" }, () => {
          localStorage.setItem('cartItemCount', "0");
          this.props.handleCartCount(0);
        })
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // shopping cart data end



    //  list AddWish start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.AddToWishCallId !== null &&
      this.AddToWishCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AddToWishCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ isMoveToWishlist: true });
        if (this.Removeappliedcoupon() &&
          this.removeLoyaltyPoints()) { this.RemoveShoppingBag() }
        // console.log('@@@@@@@@@@@@@@@@@@@@ AddWishAddWishAddWishAddWishAddWishAddWishAddWish------------------------------------------------------', responseJson.data);
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // end  AddWish 
    //  remove start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveToWishCallId !== null &&
      this.RemoveToWishCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveToWishCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // console.log('@@@@@@@@@@@@@@@@@@@@ removeremoveremoveremoveremoveremove------------------------------------------------------', responseJson.data);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // end  Remove 



    //  remove start shopping bag

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveShoppingBagCallId !== null &&
      this.RemoveShoppingBagCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveShoppingBagCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message == "cart items Removed") {
        this.shoppingCartData();
        this.shoppingPriceData();
        // console.log('@@@@@@@@@@@@@@@@@@@@ removeremoveremoveremoveremoveremove shopping------------------------------------------------------', responseJson.data);
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // end  Remove  shopping bag



    //  remove start shopping bag on discard exchange flow

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveShoppingBagOnExchangeDiscardCallId !== null &&
      this.RemoveShoppingBagOnExchangeDiscardCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveShoppingBagOnExchangeDiscardCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message == "cart items Removed") {
        this.setState({ loading: false })
        this.shoppingCartData();
        this.shoppingPriceData();
        // console.log('@@@@@@@@@@@@@@@@@@@@ removeremoveremoveremoveremoveremove shopping------------------------------------------------------', responseJson.data);
        this.props.navigation.navigate("Home")
        // this.setState({ loading: false })
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // end  Remove  shopping bag on discrad exchange flow





    // shopping price details data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.priceDetailsDataCallId !== null &&
      this.priceDetailsDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.priceDetailsDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // console.log(responseJson, "price details response")
      if (responseJson) {
        this.setState({ priceDetailsData: responseJson.data });
      }

      // console.log('////////////// Price Details data------------------------------------------------------', this.state.priceDetailsData);
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      }
      else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //shopping price details data end








    // coupons data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.couponsDataCallId !== null &&
      this.couponsDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.couponsDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ coupons: responseJson.data.reverse() });
        // console.log('//////////////coupons------------------------------------------------------', this.state.coupons);

      }
      else if (responseJson && responseJson.message) {
        this.setState({ coupons: "" });
        // console.log('//////////////coupons------------------------------------------------------', this.state.coupons);

      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //coupons data end


    // loyalty points start 


    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.loyaltyPointsCallId !== null &&
      this.loyaltyPointsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.loyaltyPointsCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ loyaltyPoints: responseJson.data });
        // console.log('//////////////loyaltyPoints------------------------------------------------------', this.state.loyaltyPoints);
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //loyalty points  end


    // active cart  id start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ActiveCartCallId !== null &&
      this.ActiveCartCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ActiveCartCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson) {
        this.setState({ activeCartId: responseJson.data })
        // console.log('////////////// activeCartId data------------------------------------------------------', this.state.activeCartId)
      }
      else { this.setState({ activeCartId: "" }) }


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      }
      else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // active cart id end 


    //applied coupons data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.appliedcouponDataCallId !== null &&
      this.appliedcouponDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.appliedcouponDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ invalidCoupon: false })
        this.setState({ appliedcoupon: responseJson.data });
        // console.log('////////////// appliedcoupon------------------------------------------------------', this.state.appliedcoupon);
        // console.log(responseJson)
        this.activeCartId();
        this.setState({ applyCoupon: false })
        this.setState({ invalidCoupon: false })
        this.setState({ couponCode: "" })
        this.shoppingPriceData()

      }
      else {
        //Check Error Response
        // console.log(responseJson.message)
        this.parseApiErrorResponse(responseJson);
        this.setState({ invalidCoupon: true })
        this.setState({ invalidAlertMessage: responseJson.message })
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);

      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //applied coupons data end



    //remove applied coupons data start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveappliedcouponDataCallId !== null &&
      this.RemoveappliedcouponDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveappliedcouponDataCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ Removeappliedcoupon: responseJson.data });
        // console.log('////////////// Removeappliedcoupon------------------------------------------------------', this.state.Removeappliedcoupon);
        this.activeCartId()
        this.shoppingPriceData()
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    //remove applied coupons data end



    // apply  loyalty points start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.applyLoyaltyPointsCallId !== null &&
      this.applyLoyaltyPointsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.applyLoyaltyPointsCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        this.setState({ applyLoyaltyPoints: responseJson.data });
        // console.log('////////////// applyLoyaltyPoints------------------------------------------------------', this.state.applyLoyaltyPoints);

        this.activeCartId()
        this.shoppingPriceData()
        this.loyaltyPoints()
        this.setState({ loyaltyPointsError: `₹${this.state.priceDetailsData?.attributes?.loyalty_point_discount} will be deducted from total MRP`, useLoyaltyPoints: "" })

      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // apply loyalty points end 



    // remove  loyalty points start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeLoyaltyPointsCallId !== null &&
      this.removeLoyaltyPointsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.removeLoyaltyPointsCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {

        this.setState({ removeLoyaltyPoints: responseJson });
        // console.log('////////////// removeLoyaltyPoints------------------------------------------------------', this.state.removeLoyaltyPoints);
        this.activeCartId()
        this.shoppingPriceData()
        this.loyaltyPoints()

        this.setState({ loyaltyPointsError: "", useLoyaltyPoints: "" })

      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // remove loyalty points end 

    // place exchange order start 
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.PlaceExchangeOrderCallId !== null &&
      this.PlaceExchangeOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.PlaceExchangeOrderCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ placeExchangeOrderData: responseJson.data });
        // console.log('////////////// place Exchange order------------------------------------------------------', this.state.placeExchangeOrderData)
      }


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // place exchange order end

  }




  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // Customizable Area Start
  async componentDidMount() {

    // console.log("component rendered")

    try {
      const localToken = await localStorage.getItem("authToken")
      // console.log(localToken, "tokentoken")
      this.setState({ token: localToken });
      this.shoppingCartData();
      this.activeCartId()
      this.loyaltyPoints()

      if (this.state.ShoppingCartData! == undefined || this.state.ShoppingCartData.length! == 0) {
        this.shoppingPriceData();
      }
      this.shoppingPriceData();
      const exchangeItem: any = localStorage.getItem("exchangeItemOrderId")
      this.setState({ exchangeItemOrderId: exchangeItem })
      if (localStorage.getItem("exchangeFlow") == "true") {
        this.setState({ exchangeFlow: true })
      } else { this.setState({ exchangeFlow: false }) }
      // this.appliedcoupon()
      // this.loyaltyPoints()

    } catch (e) {
      // error reading value
    }


  }

  // status block for adding wishlist and removing wishlist 
  StatusBlog = async (data: any, id: any) => {
    if (data) {
      this.RemoveWishList(id)



    } else {
      this.AddWishList(id)



    }
  }

  // exchange item function 
  placeExchangeorder(
    // productid: any, variantId: any
  ) {
    // console.log(Number(productid), variantId)

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      "order_item_id": this.state.exchangeItemOrderId, //here take order_item_id which item you have to exchange.
      additional_comments: "take additional comment whatever you want",
      service_type: "exchange", //It is mendatory field
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PlaceExchangeOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PlaceExchangeOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }


  // Active Cart id start 
  activeCartId() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.ActiveCartCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ActiveCartAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }




  // loyalty points get start 

  loyaltyPoints() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.loyaltyPointsCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loyaltyPointsAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }


  // loyalty points get end  







  // price details start

  shoppingPriceData() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.priceDetailsDataCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shoppingPriceAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  // price details end 

  // coupons start 

  coupons() {
    // console.log(this.state.couponsCatalogueIds)
    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.couponsDataCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponsAPIEndPoint + `[${this.state.couponsCatalogueIds}]`

    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  // coupons end 



  applyCoupon(coupon: any) {
    if (this.state.activeCartId.attributes.is_coupon_applied) { this.Removeappliedcoupon() }
    setTimeout(() => this.appliedcoupon(coupon), 1000);

  }

  // applied coupon start

  appliedcoupon(code: any) {
    // if (this.state.activeCartId.attributes.is_coupon_applied) { this.Removeappliedcoupon(); }

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      cart_id: this.state.activeCartId.id,
      coupon_code: code

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appliedcouponDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appliedcouponAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;


  }
  // applied coupon end 






  // remove coupon start 
  Removeappliedcoupon() {


    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      cart_id: this.state.activeCartId?.id,
      coupon_code: this.state.activeCartId?.attributes.coupon_code

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.RemoveappliedcouponDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveappliedcouponAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;


  }

  // remove coupon end 



  // apply loyalty points start

  onPressLoyaltyPointsApply() {
    if (this.state.redeemLoyalty == "use_all_point") {
      let body = {
        loyalty_point: "use_all_point"
      }
      this.applyLoyaltyPoints(body)
    } else if (this.state.useLoyaltyPoints == '') {
      // alert("Enter Loyalty Points to Redeem")
      this.setState({ loyaltyPointsError: "Enter Loyalty Points to Redeem" })
    } else if (isNaN(parseInt(this.state.useLoyaltyPoints))) {
      // alert('Enter Valid Loyalty Points to Redeem')
      this.setState({ loyaltyPointsError: "Enter Valid Loyalty Points to Redeem" })
    } else if (parseInt(this.state.useLoyaltyPoints) > this.state.loyaltyPoints?.attributes?.actual_points) {
      // alert('Entered points are more than available points')
      this.setState({ loyaltyPointsError: "Entered points are more than available points" })
    }
    else if (parseInt(this.state.useLoyaltyPoints) === 0 || this.state.useLoyaltyPoints.includes("+") || this.state.useLoyaltyPoints.includes("-") || this.state.useLoyaltyPoints.includes(".")) {

      this.setState({ loyaltyPointsError: "Please enter valid points" })
    }


    else {
      this.setState({ loyaltyPointsError: "" })
      let body = {
        loyalty_point: "choose_custom_point",
        use_points: parseInt(this.state.useLoyaltyPoints)
      }
      this.applyLoyaltyPoints(body)
    }
  }
  applyLoyaltyPoints(httpBody: any) {





    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };



    var httpBody = httpBody


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyLoyaltyPointsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyLoyaltyPointsAPIEndPoint.concat(this.state.activeCartId.id)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }
  // apply loyalty points end 



  removeLoyaltyPoints() {


    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeLoyaltyPointsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeLoyaltyPointsAPIEndPoint.concat(this.state.activeCartId.id)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // apiRequest.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;


  }

  // apply loyalty points end 








  applyCouponInput() {
    if (this.state.activeCartId.attributes.is_coupon_applied) { this.Removeappliedcoupon() }
    setTimeout(() => this.appliedcouponInput(), 500);

  }



  appliedcouponInput() {


    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      cart_id: this.state.activeCartId.id,
      coupon_code: this.state.couponCode

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appliedcouponDataCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appliedcouponAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;


  }

  // coupons end 



  // shopping cart data start 
  shoppingCartData() {
    // this.setState({ shoppingCartloading: true });
    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.ShoppingCartDataCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ShoppingCartAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;



  }
  // shopping cart data end 

  // place order start
  placeorder(catvarid: any, catalogueid: any) {
    //   console.log(Number(catvarid), catalogueid)
    const catvariantid = Number(catvarid)
    if (this.state.activeCartId.attributes?.is_coupon_applied) {
      this.Removeappliedcoupon()
    }
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      quantity: 1,
      catalogue_id: catalogueid,
      catalogue_variant_id: catvariantid ? catvariantid : null,
      cart_id: this.state.activeCartId.id ? this.state.activeCartId.id : null || undefined
    }

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PlaceOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PlaceOrderAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRequest.id, apiRequest);
    // console.log(this.state.activeCartId, "cart id here")
    return true;

  }


  // place ordeer end 





  //add WishList
  AddWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "catalogue_ids": [data]
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.AddToWishCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWishlistAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  //remove whishlist
  RemoveWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.RemoveToWishCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveWishlistAPIEndPoint.concat(data)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // remove from shopping bag  start
  RemoveShoppingBag() {
    // console.log(this.state.ShoppingRemoveBag, "remove product id")

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "ids": [Number(this.state.ShoppingRemoveBag)]

    }
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.RemoveShoppingBagCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveShoppingBagAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDELETE
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  // remove from shopping bag end 


  // remove from shopping bag  on clicking discard exchange
  RemoveShoppingBagOnExchangeDiscard() {
    this.setState({ ExchangeFlowDiscarded: true })
    this.setState({ loading: true })
    // console.log(this.state.ShoppingRemoveBag, "remove product id")

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "ids": [Number(this.state.ShoppingRemoveBagOnExchangeDiscard)]

    }
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.RemoveShoppingBagOnExchangeDiscardCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveShoppingBagAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDELETE
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }
  // remove from shopping bag end 




  // detailsshowhide
  showHideToggle(id: any, code: any) {

    const ele: any = document.getElementById(code)
    const eledetails: any = document.getElementById(id)

    // console.log(ele.innerText, eledetails.style.display)

    if (ele.innerText == "view details" && eledetails.style.display == "none") {
      // console.log("its true")
      ele.innerText = "Hide Details"
      eledetails.style.display = "block"
    }
    // else{ele.textContent = "view details"
    else {
      // console.log("its false")
      ele.innerText = "view details"
      eledetails.style.display = "none"
    }
  }


  // quantity update api start

  quantityUpdate() {
    // console.log(this.state.productQuantityId)
    this.setState({ sizeQuantityLoader: true })

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      quantity: this.state.quantity,

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.quantityUpdateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.quantityUpdateAPIEndPoint.concat(this.state.productQuantityId)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }

  // quantity update api end




  // size update api start

  sizeUpdate() {
    // console.log(this.state.size)
    this.setState({ sizeQuantityLoader: true })
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      catalogue_variant_id: this.state.size,

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sizeUpdateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sizeUpdateAPIEndPoint.concat(this.state.productSizeId)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }

  // size update api end
  // MoveToWishlist() {

  //   this.removeLoyaltyPoints()

  // }

  MoveToWishlistessage = () => {
    this.setState({ isMoveToWishlist: false });
  }
  RemovedMessage = () => {
    this.setState({ isRemoved: false });
  }
  ExchangeFlowDiscarded = () => {
    this.setState({ ExchangeFlowDiscarded: false });
  }
  // close delete box 

  handleClose() {
    this.setState({
      isDelete: false
    });
  }

  // Customizable Area End
}


