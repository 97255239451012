import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import Rating from '@material-ui/lab/Rating';
import WriteReviewModal from "./WriteReviewModal.web";
import Typography from '@material-ui/core/Typography';
import MyOrderDetailController, {
    Props,
    configJSON
} from "./MyOrderDetailController.web";
import {
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@material-ui/core";

import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import Loader from "../../../components/src/Loader.web"
// import { CatalogueVariantModel, ProductModel, ReviewModal } from "../../../framework/src/Interfaces/IProductDetail";
import { OrderItemModel, Review } from "../../../framework/src/Interfaces/IOrderDetail";


// web Order Details 1 start


const styles: any = {
    userouter: {
        //   padding: '50px 0',
        "& .order-title-wrapper": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 0 20px",
            borderBottom: "1px solid #e6e6e6",
        },
        "& .order-title-wrapper .select-border": {
            margin: "0",
        },
        "& h2": {
            fontSize: "18px",
            lineHeight: "22px",
            color: "#121212",
            fontWeight: "700",
            margin: "0",
            textTransform: "uppercase",
            '@media (max-width:767px)': {
                margin: "0 0 10px",
            }
        },
        "& .user-detail-wrapper": {
            padding: "30px 20px",
            background: "#f3f3f3",
            '@media (max-width:1024px)': {
                padding: "20px",
            },
            '@media (max-width:767px)': {
                padding: "15px",
            }
        },
        "& .user-detail-wrapper .user-detail-inner:not(:last-child)": {
            margin: "0 0 20px",
        },
        "& .user-detail-inner": {
            background: "#fff",
            padding: "23px 20px",
            '@media (max-width:1024px)': {
                padding: "20px",
            },
            '@media (max-width:767px)': {
                padding: "15px",
            }
        },
        "& .productlist-wrapper": {
            padding: "0",
        },
        "& .productlist-wrapper .product-list": {
            border: "0",
            background: "#f6f6f6",
            padding: "15px",
            position: "relative",
            '@media (max-width:767px)': {
                padding: "10px",
            }
        },
        "& .productlist-wrapper .product-list .product-img": {
            width: "72px",
            height: "103px"

        },
        "& .productlist-wrapper .product-list .product-img img": {
            borderRadius: "2.5px"

        },

        "& .productlist-wrapper .product-list .product-detail": {
            paddingLeft: "12px",
        },
        "& .productlist-wrapper .product-name": {
            fontSize: "16px",
            lineHeight: "20px",
            '@media (max-width:767px)': {
                fontSize: "14px",
                lineHeight: "18px",
            }
        },
        "& .productlist-wrapper .product-desc": {
            color: "#5d5d66",
            margin: "0 0 7px"
        },
        "& .productlist-wrapper .product-desc.size-qty": {
            margin: "0 0 0",
        },
        "& .rating-blog": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #e3e3e3",
            marginTop: "11px",
            padding: "10px 0 0",
            '@media (max-width:767px)': {
                flexWrap: "wrap",
            }
        },
        "& .rating": {
            display: "flex",
            alignItems: "center",
            '@media (max-width:767px)': {
                flexWrap: "wrap",
            }
        },
        "& .rating p": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#b6b6b6",
            margin: "0 12px 0 0",
        },
        "& .rating .product-rating .MuiIconButton-root": {
            padding: "0",
            margin: "0 9px 0 0",
        },
        "& .rating .product-rating .MuiIconButton-root.active .MuiIconButton-label span": {
            display: "none",
        },
        "& .rating .product-rating .MuiIconButton-root.active .MuiIconButton-label .fill": {
            display: "inline-block",
        },
        "& .rating .product-rating .MuiIconButton-root .fill": {
            display: "none",
        },
        "& .rating .product-rating .MuiIconButton-root .fill svg": {
            color: "#ffc107",
        },
        "& .rating .product-rating .MuiSvgIcon-root": {
            color: "#b6b6b6",
            width: "30px",
            height: "30px",
        },
        "& .write-review a": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#ff8030",
            fontWeight: "600",
            textDecoration: "none",
        },
        "& .msg-info.success": {
            background: "#21c998",
        },
        "& .msg-info": {
            padding: "15px 20px",
            margin: "0 0 18px",
            '@media (max-width:767px)': {
                padding: "10px 15px",
            }
        },
        "& .msg-info h6": {
            fontSize: "16px",
            lineHeight: "20px",
            color: "#fff",
            margin: " 0 0 6px",
            fontWeight: "600",
        },
        "& .msg-info p": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#fff",
            margin: " 0 0 0",
        },
        "& .product-order-wrapper h4": {
            fontSize: "16px",
            lineHeight: "20px",
            color: "#121212",
            fontWeight: "600",
            margin: " 0 0 18px",
        },
        "& .product-order-wrapper p": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#212121",
            fontWeight: "500",
            margin: " 0 0 16px",
        },
        "& .product-order-wrapper p span": {
            display: "inline-block",
            minWidth: "131px",
            color: "#5d5d66",
            fontWeight: "400",
        },
        "& .product-order-list p:last-child": {
            margin: "0",
        },
        "& .product-order-list .total": {
            padding: "15px 0 0",
            borderTop: "1px solid #e3e3e3",
        },
        "& .product-order-list .total p": {
            fontWeight: "600",
        },
        "& .product-order-list .total p span": {
            fontWeight: "600",
            color: "#212121",
        },
        "& .return-exchange p": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#5d5d66",
            margin: "9px 0 13px",
        },
        "& .return-exchange .outline-btn": {
            minWidth: "180px",
            fontSize: "14px",
            height: "42px",
            textTransform: "uppercase",
            '@media (max-width:575px)': {
                width: "100%",
            }
        },
        "& .return-exchange .btn-wrapper .outline-btn:not(:last-child)": {
            margin: " 0 22px 0 0",
            '@media (max-width:575px)': {
                margin: " 0 0 15px 0",
            }
        },
        "& .address-blog p:last-child": {
            margin: "0",
        },
        "& .address-blog p b": {
            fontWeight: "600",
            fontSize: "16px",
            color: "#121212",
        },
        "& .address-blog p": {
            color: "#5d5d66",
            fontWeight: "400",
        },
        "& .trackorder p": {
            color: "grey",

        },
        "& .trackorder ": {
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between"

        },
    },
};

export class MyOrderDetail extends MyOrderDetailController {



    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
    callCustomerOrders = (): void => {
        // console.log("function called by props:::")
        this.setState({ openAddReviewModal: false }
            , () => this.specificOrderData()
        );
        // this.getCustomerOrders('');
    }

    getColorCodeFromStatus = (itemStatus: string) => {
        let color = "";
        if (itemStatus === 'placed' || itemStatus === 'ready_to_ship' || itemStatus === 'shipped' || itemStatus === 'sent') {
            color = '#EEF1F6'
        } else if (itemStatus === 'delivered' || itemStatus === 'exchange') {
            color = '#E9FAF4'
        }
        return color
    }

    getBackGroundColorCodeFromStatus = (itemStatus: string) => {
        let backgroundColor = "";
        if (itemStatus === 'placed' || itemStatus === 'shipped') {
            backgroundColor = '#5A7994'
        } else if (itemStatus === 'delivered') {
            backgroundColor = '#4CC3A0'
        } else if (itemStatus === 'exchange') {
            backgroundColor = '#BB2FE1'
        }  else if (itemStatus === 'cancelled') {
            backgroundColor = '#f94a59'
        }
        return backgroundColor
    }

    returnImageURL = (item: any) => {
        return item.galleries && item.galleries.length && item.galleries[0].url || '';
    }

    renderCancelItemButton = (item: any, product_name: string) => {
        return (
            !item.is_order_confirm ?
                <>
                    <div className="btn-wrapper">

                        <p>This item can be cancelled before shipping order</p>
                        <Button type="button" variant="contained" className="outline-btn"
                            onClick={(e: any) => {
                                e?.stopPropagation()
                                this.setState({
                                    displayCancelItemOrderModal: true,
                                    order_item_id: item.id,
                                    productName: product_name
                                    // cancelOrderId: orderNumber,
                                    // order: order
                                });
                                localStorage.setItem("itemdetail", JSON.stringify(item))
                            }}
                        >
                            Cancel Item
                        </Button>

                    </div>
                </>
                :
                " "
        )
    }

    renderReturnExchangeItemButton = (item: any, productPolicyDays: number, index: number) => {
        return (
            <>
                {item.is_it_exchangeble || item.is_it_returnable ?
                    <p>This item can be returned or exchanged within {productPolicyDays} days</p>
                    : ""
                }
                {item.is_it_exchangeble ?
                    <Button type="button" variant="contained" className="outline-btn" onClick={(e) => {
                        localStorage.setItem("exchangeFlow", JSON.stringify(true));
                        localStorage.setItem("exchangeItemOrderId", JSON.stringify(item.id))
                        this.setState({
                            cat_id: item.catalogue_id
                        })

                        if (this.state.itemsInCart) {
                            this.setState({
                                popup: true
                            })
                        } else (this.setState({
                            exchangeItems: true
                        }))

                    }}>
                        Exchange Item
                    </Button>
                    : <></>
                }
                {item.is_it_returnable ?
                    <Button onClick={() => {
                        this.props.navigation.navigate("returnOrder")
                        // localStorage.setItem("productdata", JSON.stringify(this.state.specificrderData.attributes?.catalogue_details[index]))
                        localStorage.setItem("productdetails", JSON.stringify(this.state.specificOrderData.attributes?.order_items_details[index]))


                    }} type="button" variant="contained" className="outline-btn">Return Item</Button>
                    : <></>
                }
            </>
        )
    }

    renderReviewActionButton = (customerReview: any, orderItemDetail: any, rating: number) => {
        return (
            <>
                {!customerReview?.id &&
                    <>
                        <div className="rating">
                            <p>Rate Product</p>
                            <Rating name="read-only" value={0} readOnly />
                        </div>
                        <div className="write-review">
                            <Link
                                onClick={(e: any) => {
                                    e.stopPropagation()
                                    this.setState({
                                        openAddReviewModal: true,
                                        selectedProductItemDetail: orderItemDetail
                                    });
                                }}>Write Review</Link>
                        </div>
                    </>
                }
                {customerReview?.id &&
                    <>
                        <div className="rating">
                            <Rating name="read-only" value={rating} readOnly />
                        </div>
                        <div className="write-review">
                            <Link
                                onClick={(e: any) => {
                                    e?.stopPropagation()
                                    this.setState({
                                        openDeleteReviewModal: true,
                                        reviewId: Number(customerReview?.id) || 0
                                    });
                                }}>Delete Review</Link>
                        </div>
                    </>
                }
            </>
        )
    }

    // Customizable Area Start
    // Customizable Area End


    renderOrderDetail = () => {
        const { classes } = this.props;
        const order = this.state.specificOrderData
        const orderDate = order && order.attributes && order.attributes.updated_at;
        const updatedOrderDate = moment(orderDate).format('ddd, ll');
        const created_at_date = this.state.specificOrderData.attributes?.created_at
        const created_order_date = moment(created_at_date).format("L")

        const status = order.attributes?.status

        return (<>


            {/* web Order Details 1 start */}
            <div ref={this.myRef} className={classes.userouter}>

                <Container maxWidth="lg">
                    <Grid container spacing={4}>

                        {console.log(this.props, "detials")}
                        <Grid item xs={12} >
                            <div className="user-detail-wrapper" >
                                <Loader loading={this.state.dataLoader} />

                                <div className="user-detail-inner">
                                    <div className="product-order-wrapper">
                                        <h4>Order Details</h4>
                                        <div className="product-order-list">
                                            <p><span>Order ID</span> : {this.state.specificOrderData.attributes?.order_number}</p>
                                            <p><span>Order Date</span> : {created_order_date}</p>
                                            <p><span>Items In order</span> :  {this.state.specificOrderData.attributes?.order_items_details.map((item: any) => item.quantity).reduce((a: any, b: any) => a + b, 0)}</p>
                                        </div>

                                    </div>
                                </div>
                                {this.state.specificOrderData.attributes?.order_items_details.map((item: OrderItemModel, index: any) => {
                                    // const productDetail = this.state.specificOrderData.attributes?.catalogue_details[index].attributes

                                    // changes as per new changes
                                    const productPolicyDays = item?.return_policy?.days
                                    // const productPolicyDays = 10;

                                    // reviews 
                                    const itemStatus = item.status
                                    const catalogue_id = Number(item.catalogue_id);
                                    const catalogue_variant_id = Number(item.catalogue_variant_id);

                                    const product_name = item?.product_name;
                                    const brand_name = item?.brand;
                                    const imageUrl = this.returnImageURL(item);
                                    const size = item?.measurement_size_value;
                                    const description = item?.description;
                                    const color = item?.color;
                                    // change as per new strcuture
                                    // const reviews = catalogue?.attributes?.review?.data && catalogue.attributes.review.data.length ? catalogue.attributes.review.data : [];
                                    const reviews = item?.review;
                                    const orderId = order.id;
                                    let customerReview: Review | undefined;
                                    let rating: number | undefined = 0;
                                    if (reviews && reviews.length) {
                                        customerReview = reviews[0];
                                        rating = reviews[0].rating;
                                    }
                                    const orderItemDetail = {
                                        orderId,
                                        catalogue_id,
                                        product_name,
                                        description,
                                        imageUrl,
                                        size,
                                        color
                                    };


                                    const textColor = this.getColorCodeFromStatus(itemStatus);
                                    const backgroundColor = this.getBackGroundColorCodeFromStatus(itemStatus)

                                    return (<>
                                        <div className="user-detail-inner">
                                            <div

                                                // onClick = {()=>{ this.setState({clickedProduct:item});this.props.navigation.navigate("returnOrder")}}
                                                className="productlist-wrapper">
                                                <div className="msg-info success"
                                                    style=
                                                    {{ display: "flex", justifyContent: "space-between", backgroundColor: backgroundColor }}
                                                >
                                                    <div >   <h6
                                                        style={{ textTransform: "capitalize", color: textColor }}
                                                    >{itemStatus}</h6>
                                                        <p >{updatedOrderDate}</p>
                                                    </div>
                                                    {/* track ordr change start  */}

                                                    {itemStatus === 'shipped' || itemStatus === 'sent' ? <><div style={{ backgroundColor: "white" }}   >

                                                        <Button

                                                            type="button"
                                                            variant="contained"
                                                            className="outline-btn"
                                                        >
                                                            Track Order
                                                        </Button></div> </> : ""}

                                                    {/* track order change end  */}
                                                </div>
                                                <div className="product-list">
                                                    <div className="product-list-inner">
                                                        <div className="product-img">
                                                            <img className="img-block" src={imageUrl} />
                                                        </div>
                                                        <div className="product-detail">
                                                            <div className="product-heading">
                                                                <h4 className="product-name">{product_name}</h4>
                                                            </div>
                                                            <div className="product-desc">
                                                                {/* {brand_name} */}
                                                                {description}

                                                            </div>
                                                            <div className="product-desc">
                                                                <span>Size : {size} </span>

                                                            </div>
                                                            <div className="product-desc">

                                                                <span>quantity : {item.quantity} </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="return-exchange">

                                                        {itemStatus == "delivered" ? <>

                                                            <div className="rating-blog">
                                                                {this.renderReviewActionButton(customerReview, orderItemDetail, rating)}
                                                            </div>
                                                            <div className="btn-wrapper">
                                                                {this.renderReturnExchangeItemButton(item, productPolicyDays, index)}
                                                            </div></>
                                                            : " "}

                                                        {/* {this.state.specificOrderData.attributes?.status == "confirmed" || this.state.specificOrderData.attributes?.status == "placed" ? */}
                                                        <p style={{ color: "blue" }}>This item ships within 3-5 days</p>
                                                        {this.renderCancelItemButton(item, product_name)}
                                                    </div>
                                                </div>


                                            </div>
                                            {/* Exchange Item */}
                                            <Dialog
                                                aria-labelledby="customized-dialog-duplicate"
                                                open={this.state.exchangeItems}
                                                className="select-dialog"
                                            >
                                                <DialogTitle id="customized-dialog-title">
                                                    <span>Exchange Item</span>
                                                    <CloseIcon
                                                        className="close-dialog"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                exchangeItems: false,
                                                            });
                                                        }}
                                                    />
                                                </DialogTitle>
                                                <DialogContent style={{ width: "370px" }}>
                                                    <RadioGroup className="custom-radio mrb-25" name="price">
                                                        <FormControlLabel
                                                            value="yes"
                                                            control={<Radio color="default" />}
                                                            label="With different size & color"
                                                            onClick={() => { this.props.navigation.navigate("ProductDetail", { productId: this.state.cat_id }) }}
                                                        />
                                                        <FormControlLabel
                                                            value="no"
                                                            control={<Radio color="default" />}
                                                            label="Others shopping"
                                                            onClick={() => { this.props.navigation.navigate("Home") }}
                                                        />
                                                    </RadioGroup>
                                                </DialogContent>
                                            </Dialog>
                                        </div>  </>)
                                })}

                                <div className="user-detail-inner">
                                    <div className="product-order-wrapper">
                                        <h4>Delivery Address</h4>
                                        <div className="address-blog">
                                            <p><b>{this.state.specificOrderData.attributes?.delivered_at?.name}</b></p>
                                            <p>
                                                {this.state.specificOrderData.attributes?.delivered_at?.address},
                                                {this.state.specificOrderData.attributes?.delivered_at?.city},
                                                {this.state.specificOrderData.attributes?.delivered_at?.state} -
                                                {this.state.specificOrderData.attributes?.delivered_at?.zip_code}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-detail-inner">
                                <div className="product-order-wrapper">
                                    <h4>Price Details</h4>
                                    <div className="product-order-list">
                                        <p><span>Total MRP</span> : ₹{this.state.specificOrderData.attributes?.sub_total || 0}</p>
                                        <p><span>Discount on MRP</span> : -₹{this.state.specificOrderData.attributes?.total_discount_on_mrp || 0}</p>
                                        <p><span>Loyalty Discount</span> : -₹{this.state.specificOrderData.attributes?.loyalty_discount || 0}</p>
                                        <p><span>Coupon Discount</span> : -₹{this.state.specificOrderData.attributes?.coupon_discount_amount || 0}</p>
                                        <p><span>GST</span> : ₹{this.state.specificOrderData.attributes?.total_tax || 0}</p>
                                        <p><span>Delivery Fee</span> : ₹{this.state.specificOrderData.attributes?.shipping_charge || 0}</p>
                                        <div className="total">
                                            <p><span>Total Amount</span> : ₹{this.state.specificOrderData.attributes?.total || 0}</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>



            {/* cancel order */}
            <Dialog
                aria-labelledby="customized-dialog-duplicate"
                open={this.state.displayCancelOrderModal}
                className="cancel-order order-dialog"
            >
                <DialogTitle id="customized-dialog-title">
                    <CloseIcon
                        className="close-dialog"
                        onClick={(e) => {
                            this.setState({ displayCancelOrderModal: false });
                        }}
                    />
                </DialogTitle>
                <DialogContent style={{ width: "370px" }}>
                    <h2 className="modal-heading">
                        Are you sure you want to cancel the order?
                    </h2>
                    <div className="sub-txt">Order ID: {this.state.specificOrderData.attributes?.order_number}</div>
                    <div className="btn-wrapper">
                        <Button
                            href=""
                            type="submit"
                            variant="contained"
                            className="outline-btn"
                            onClick={(e) => { this.setState({ displayCancelOrderModal: false }) }}
                        >
                            No
                        </Button>
                        <Button
                            href=""
                            type="submit"
                            variant="contained"
                            className="black-btn"
                            onClick={(e) => {
                                this.goToCancelOrderScreen();
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {/* cancel item  */}

            <Dialog
                aria-labelledby="customized-dialog-duplicate"
                open={this.state.displayCancelItemOrderModal}
                className="cancel-order order-dialog"
            >
                <DialogTitle id="customized-dialog-title">
                    <CloseIcon
                        className="close-dialog"
                        onClick={(e) => {
                            this.setState({ displayCancelItemOrderModal: false });
                        }}
                    />
                </DialogTitle>
                <DialogContent style={{ width: "370px" }}>
                    <h2 className="modal-heading">
                        Are you sure you want to cancel the below item from the order?
                    </h2>
                    <div className="sub-txt">Product name: {this.state.productName}</div>
                    <div className="btn-wrapper">
                        <Button
                            href=""
                            type="submit"
                            variant="contained"
                            className="outline-btn"
                            onClick={(e) => { this.setState({ displayCancelItemOrderModal: false }) }}
                        >
                            No
                        </Button>
                        <Button

                            type="submit"
                            variant="contained"
                            className="black-btn"
                            onClick={() => {
                                this.goToCancelItemScreen()
                            }}


                        >
                            Yes
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {/* pop up to discard bag or move to wishlist */}
            <Dialog
                open={this.state.popup}
                aria-labelledby="simple-dialog-title"
                fullWidth={true} maxWidth={"xs"}
            >
                <Box className="myBox" >
                    <Box textAlign="right" style={{
                        paddingRight: "11px",
                        paddingTop: "6px"
                    }}>
                        <span className="close-btn" style={{ cursor: "pointer" }}>
                            <CloseIcon
                                onClick={() => this.handleClosePop()}
                            ></CloseIcon>
                        </span>
                    </Box>
                    <Box className="modal-head" style={{ padding: "17px" }} fontWeight="600" p={1}>
                        <Typography style={{ textAlign: "justify" }} variant="h5">There are few items in the bag which will  be moved to wishlist in order to process the exchange.
                            Do you want to continue ?<b>
                                {/* {this.state.sizeChartToDelete.attributes?.product_name} */}
                            </b>
                            {/* size chart? */}

                        </Typography>
                    </Box>
                    <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">
                        <Button className="black-btn"
                            onClick={() => { localStorage.removeItem("exchangeFlow"); this.setState({ exchangeFlow: false }); this.handleClosePop() }}
                            size="medium"
                        >
                            Discard exchange
                        </Button>
                        <Button className="black-btn"
                            onClick={(e) => {


                                this.addToWishist();

                                //  this.addToCart(this.state.productId)
                            }

                            }
                            size="medium"
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Dialog>




            {/* delete review */}
            <Dialog
                aria-labelledby="customized-dialog-duplicate"
                open={this.state.openDeleteReviewModal}
                className="cancel-order order-dialog"
            >
                <DialogTitle id="customized-dialog-title">
                    <CloseIcon
                        className="close-dialog"
                        onClick={(e) => {
                            this.setState({ openDeleteReviewModal: false });
                        }}
                    />
                </DialogTitle>
                <DialogContent style={{ width: "370px" }}>
                    <h2 className="modal-heading">
                        Are you sure you want to delete the review?
                    </h2>
                    {/* <div className="sub-txt">Order ID: {this.state.cancelOrderId}</div> */}
                    <div className="btn-wrapper">
                        <Button
                            href=""
                            type="submit"
                            variant="contained"
                            className="outline-btn"
                            onClick={(e) => { this.setState({ openDeleteReviewModal: false }) }}
                        >
                            No
                        </Button>
                        <Button
                            href=""
                            type="submit"
                            variant="contained"
                            className="black-btn"
                            onClick={(e) => {
                                this.deleteReview();
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {this.state.openAddReviewModal &&
                <WriteReviewModal
                    navigation={this.props.navigation}
                    callBackFunction={this.callCustomerOrders}
                    hideWriteReviewModal={this.hideWriteReviewModal}
                    returnItem={this.state.selectedProductItemDetail}
                />
            }
        </>)
    }
    render() {
        const { classes } = this.props;

        return (
            <>

                <CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend}>
                    {this.renderOrderDetail()}
                </CustomerAccountSideBarWeb>
            </>
        );
    }
}
export default withStyles(styles)(MyOrderDetail);
